<template>
  <div class="GlobalTable">
    <div class="button_top">
      <div class="faultBtn">
        <div
          :class="financeRepayment === '00' ? 'active' : 'none'"
          @click="btnClick('00')"
        >
          待还款({{ applyNumber.unRepayNum || 0 }})
        </div>
        <div
          :class="financeRepayment === '01' ? 'active' : 'none'"
          @click="btnClick('01')"
        >
          已还款({{ applyNumber.repayNum || 0 }})
        </div>
      </div>
    </div>
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="seachDataList"
      :currentPage="page.pageNum"
      :total="page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="订单号" slot="orderNo" align="center">
        <template slot-scope="{ row }">
          <div>
            <p>
              {{ row.phoneName || "--" }}
              <span v-if="row.phoneStorage">({{ row.phoneStorage }})</span>
            </p>
            <p @click="sendDtailClick(row)" class="blue_underline">
              {{ row.orderNo }}
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="串号/条码" slot="imei" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.imei }}</span>
          <p>绑定条码：{{ row.orderUnique }}</p>
        </template>
      </el-table-column>
      <el-table-column label="回收商/报价师" slot="merchantname" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.manualMerchantName }}</span>
          <p>{{ row.exclusiveQuoterName }}-{{ row.exclusiveQuoterPhone }}</p>
        </template>
      </el-table-column>
      <el-table-column label="商家实际报价" slot="price" align="center">
        <template slot-scope="{ row }">
          <span style="color: red">¥{{ row.manualOriginalQuotePrice }}</span>
        </template>
      </el-table-column>
      <el-table-column slot="state" label="订单状态" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.state === '00'">待确认</span>
          <span v-else-if="row.state === '01'">已确认</span>
          <span v-else-if="row.state === '02'">待支付</span>
          <span v-else-if="row.state === '03'">已绑码</span>
          <span v-else-if="row.state === '04'">已收货</span>
          <span v-else-if="row.state === '05'">降价收货</span>
          <span v-else-if="row.state === '10'">已取消</span>
          <span v-else-if="row.state === '20'">已作废</span>
          <span v-else-if="row.state === '30'">已退回</span>
          <p v-if="row.state === '01'" style="color: #ffaf29">门店待发货</p>
        </template>
      </el-table-column>
      <el-table-column label="借款/授信比例" slot="productNum" align="center">
        <template slot-scope="{ row }">
          <div class="wrapStyle">
            <span>借款金额：{{ row.financeAmount }}</span>
            <p>授信比例：{{ row.financeRatio }}%</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="累计服务费" slot="servicecharge" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.financeInterest }}</span>
          <p class="blue_underline" @click="Interestdetail(row)">计息明细</p>
        </template>
      </el-table-column>
      <el-table-column
        label="还款方式"
        v-if="financeRepayment !== '00'"
        slot="moderepayment"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>{{
            row.financeRepaymentType == "01"
              ? "入库自动还款"
              : row.financeRepaymentType == "02"
              ? "到期自动还款"
              : row.financeRepaymentType == "03"
              ? "退单自动还款"
              : "手动还款"
          }}</span>
          <p
            v-if="row.financeRepaymentType == '04'"
            class="blue_underline"
            @click="seaRepaymentRecord(row)"
          >
            查看记录
          </p>
        </template>
      </el-table-column>
      <el-table-column
        label="预计还款时间"
        v-if="financeRepayment === '00'"
        slot="repaymenttime"
        align="center"
      >
        <template slot-scope="{ row }">
          <div class="wrapStyle">
            <span>{{ row.financeAutoRepaymentTime }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="还款时间"
        v-if="financeRepayment !== '00'"
        slot="huanTime"
        align="center"
      >
        <template slot-scope="{ row }">
          <div class="wrapStyle">
            <span>{{ row.financeRepaymentTime }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        slot="action"
        label="操作"
        align="center"
        width="200"
      >
        <template slot-scope="{ row }">
          <el-button
            v-if="financeRepayment === '00'"
            @click="repaymentClick(row)"
            type="danger"
            size="mini"
            >手动还款</el-button
          >
          <el-button @click="handleClick(row)" type="primary" size="mini"
            >查看账单</el-button
          >
        </template>
      </el-table-column>
    </GlobalTable>
    <el-dialog
      title="操作记录"
      :visible.sync="rerecoderVisible"
      :close-on-click-modal="false"
      width="450px"
    >
      <div style="margin-bottom: 20px;">操作人：{{ recorderData.adminName }}-{{ recorderData.adminAcc }}</div>
      <div style="margin-bottom: 30px;">操作时间：{{ recorderData.createTime }}</div>
    </el-dialog>
    <el-dialog
      title="还款提示"
      :visible.sync="repaymentVisible"
      :close-on-click-modal="false"
      width="450px"
    >
      <div style="text-align: center; margin-bottom: 10px; font-size: 16px">
        是否确认将该订单还款？
      </div>
      <div style="text-align: center">
        <p>
          机型：{{ repaymentData.phoneName }}，还款商家：{{
            repaymentData.manualMerchantName
          }}
        </p>
      </div>
      <span slot="footer">
        <el-button size="small" @click="repaymentVisible = false"
          >关闭</el-button
        >
        <el-button size="small" type="primary" @click="Surerepayment"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 商品列表 -->
    <el-dialog
      title="计息明细"
      :visible.sync="goodsVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <div>服务费：{{ InterestdetailData.financeInterest }}元</div>
      <div class="price_detail">
        <p>借款金额：{{ InterestdetailData.financeAmount }}元</p>
        <p v-if="InterestdetailData.financeInterestType == '02'">
          服务费计算方式：固定金额，{{
            InterestdetailData.financeInterestRegion
          }}：{{ InterestdetailData.financeInterest }}元
        </p>
        <p v-else>
          服务费计算方式：按比例，{{
            InterestdetailData.financeInterestRegion
          }}：万分之{{ InterestdetailData.financeInterestRate }}，上限{{
            InterestdetailData.financeInterestLimit
          }}元
        </p>
      </div>
      <span slot="footer">
        <el-button size="small" @click="goodsVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "Table",
  data() {
    return {
      goodsVisible: false,
      repaymentVisible: false,
      rerecoderVisible:false,
      repaymentData: {},
      recorderData: {},
      btnLoading: false,
      currentRow: {},
      loading: false,
      seachDataList: [],
      financeRepayment: "00",
      command: "",
      applyNumber: {},
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      tableColumns: [
        { slotName: "orderNo" }, //订单编号
        { slotName: "imei" }, //串号/条码
        { label: "门店商户", prop: "createCompanyName" },
        { slotName: "merchantname" }, //回收商/报价师
        { slotName: "price" }, //商家实际报价
        { slotName: "state" }, //状态
        { label: "借款时间（成交时间）", prop: "borrowTime", width: "150px" },
        { slotName: "productNum" }, //借款/授信比例
        { slotName: "servicecharge" }, //累计服务费
        { slotName: "moderepayment" }, //还款方式
        { slotName: "repaymenttime" }, //预计还款时间
        { slotName: "huanTime" }, //还款时间
        { slotName: "action" },
      ],
      InterestdetailData: {},
    };
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  created() {
    this.handleCurrentChange();
  },
  watch: {
    SeachParams(newVal) {
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
  },
  methods: {
    seaRepaymentRecord(row) {
      _api
        .financeRepaymentrecorder({ orderNo: row.orderNo })
        .then((res) => {
          this.rerecoderVisible = true;
          this.recorderData=res.data
        });
     
    },
    sendDtailClick(row) {
      let routeData = this.$router.resolve({
        path: "/RecallOrder/Details",
        query: { type: "edit", id: row.orderNo },
      });
      window.open(routeData.href, "_blank");
    },
    Interestdetail(row) {
      this.InterestdetailData = row;
      this.goodsVisible = true;
    },
    handleClick(row) {
      let routeData = this.$router.resolve({
        path: "/creditManagement/loanStatement",
        query: { id: row.orderNo },
      });
      window.open(routeData.href, "_blank");
    },
    repaymentClick(row) {
      this.repaymentData = row;
      this.repaymentVisible = true;
    },
    Surerepayment() {
      _api
        .financeRepayment({ orderNo: this.repaymentData.orderNo })
        .then((res) => {
          this.$message({
            message: "还款成功！",
            type: "success",
          });
          this.repaymentVisible = false;
          this.handleCurrentChange();
        });
    },
    // 表格切换页面
    handleCurrentChange(val) {
      console.log("列表查询", val);
      if (val) {
        this.page.pageNum = val;
      }
      const baseRequest = {
        borrowEndTime: this.SeachParams.borrowEndTime,
        borrowStartTime: this.SeachParams.borrowStartTime,
        code: this.SeachParams.code,
        companyId: this.SeachParams.companyId,
        financeRepayment: this.financeRepayment,
        financeRepaymentType: this.SeachParams.financeRepaymentType,
        imei: this.SeachParams.imei,
        merchantId: this.SeachParams.merchantId,
        orderNo: this.SeachParams.orderNo,
        repayEndTime: this.SeachParams.repayEndTime,
        repayStartTime: this.SeachParams.repayStartTime,
        state: this.SeachParams.state,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true;
      _api.getborrowList(baseRequest).then((res) => {
        if (res.code === 1) {
          this.seachDataList = res.data.list.records;
          this.applyNumber.repayNum = res.data.repayNum;
          this.applyNumber.unRepayNum = res.data.unRepayNum;
          this.page.total = res.data.list.total;
          this.page.pageNum = res.data.list.current;
          this.loading = false;
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding == "function") {
          this.SeachParams.disuseLoding();
        }
      });
    },
    btnClick(type) {
      this.financeRepayment = type;
      this.$store.commit("tagsView/SETPRICE", type);
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  .wrapStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .staff-list-form {
    .el-input {
      width: 170px;
    }
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .button_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .repair-conent {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .faultBtn {
    //width: 432px;
    height: 34px;
    background: #f9fbfd;
    border: 1px solid #c1d1ff;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    cursor: pointer;
    .active {
      text-align: center;
      color: white;
      padding: 0 20px;
      // width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      background: #0981ff;
      border-radius: 20px;
    }
    .none {
      text-align: center;
      color: #333;
      padding: 0 20px;
      // width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      border-radius: 20px;
    }
  }
  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }

  .remarks-tip {
    color: #0981ff;
    font-size: 16px;
    padding-left: 10px;
    border-left: 3px solid;
    margin-bottom: 10px;
  }
  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}
.price_detail {
  border-radius: 10px;
  margin-top: 10px;
  padding: 15px 10px;
  background-color: #f2f2f2;
  > p {
    margin: 5px;
  }
}

.call_accounts_info {
  margin: 10px 0;
}

.call_accounts_check {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 14px;
}

.call_accounts_result {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.el_from {
  margin-left: 30px;
  .form_flex_cont {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
  }
  .drawerWith {
    width: 500px;
  }
  .timeStyle {
    margin-top: 15px;
  }
}
.tagStyle {
  border: 1px solid #0981ff;
  border-radius: 10px;
  padding: 3px 10px;
  color: #0981ff;
  margin-right: 10px;
}
.blue_underline {
  color: #0981ff;
  cursor: pointer;
  text-decoration: underline;
}
</style>
