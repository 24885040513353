<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="80"
      round
      :needBtnLoading="true"
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
    <el-form-item class="el_from select_down" label="借款回收商" slot="merchantId" style="margin:0" label-width="85px">
        <el-select  v-model="merchantId" filterable placeholder="请选择回收商" clearable>
          <el-option v-for="item in machineType" :key="item.merchantId" :label="item.merchantName" :value="item.merchantId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单状态" slot="state" style="margin:0">
        <el-select
          v-model="state"
          placeholder="请选择订单状态"
          clearable
        >
          <el-option
            v-for="item in statusList"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <template>
        <el-button
          icon="el-icon-download"
          size="small"
          type="success"
          :loading="exportLoading"
          @click="exportExcel"
          >导出Excel</el-button
        >
      </template>
    </GlobalForm>
  </div>
</template>

<script>
import { MessageBox } from "element-ui";
import _api from "@/utils/request";
import moment from "moment";
export default {
  name: "Form",
  data() {
    return {
      state:"",
      statusList:[],
      merchantId:"",
      machineType:[],
        initData: {
          entryTime: [
            moment().day(moment().day()).format("YYYY-MM-DD 00:00:00"),
            moment().format("YYYY-MM-DD 23:59:59"),
          ],
        },
        pickerMinDate: null,
        pickerMaxDate: null,
        day31: 30 * 24 * 3600 * 1000,
        formItemList: [
          {slotName:"merchantId"},
            {
              labelName: "门店商家",
              key: "companyId",
              type: "selectFilterable",
              placeholder: "请选择",
              lableWidth: "78px",
              clear: true,
              option: [],
              clearFun: () => false,
            },
            {
          key: "orderNo",
          type: "input",
          labelName: "订单编号",
          placeholder: "请输入订单编号",
          lableWidth: "75px",
        }, {
          key: "imei",
          type: "input",
          labelName: "旧机串号",
          placeholder: "请输入旧机串号",
          lableWidth: "75px",
        },{
          key: "code",
          type: "input",
          labelName: "绑定条码",
          placeholder: "请输入绑定条码",
          lableWidth: "75px",
        },
        {slotName:"state"},
            {
                labelName: "还款方式",
                key: "financeRepaymentType",
                type: "selectFilterable",
                placeholder: "请选择",
                lableWidth: "78px",
                clear: true,
                option: [{
                  label:"入库自动还款",
                  value:"01",
                },{
                  label:"到期自动还款",
                  value:"02",
                },{
                  label:"退单自动还款",
                  value:"03",
                },{
                  label:"手动还款",
                  value:"04",
                }],
                clearFun: () => false,
            },
            {
                key: "entryTime",
                type: "pickerOptions",
                labelName: "借款时间",
                clearable: true,
                lableWidth: '50',
                placeholder: "请选择时间",
                valueFormat: "timestamp",
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                    if (minDate && this.pickerMinDate) {
                        this.pickerMinDate = null;
                    } else if (minDate) {
                        this.pickerMinDate = minDate.getTime();
                    }
                    },
                    disabledDate: (time) => {
                    if (this.pickerMinDate) {
                        return (
                        time.getTime() > this.pickerMinDate + this.day31 ||
                        time.getTime() < this.pickerMinDate - this.day31
                        );
                    }
                    return false;
                    },
                },
            }, {
                key: "refundTime",
                type: "pickerOptions",
                labelName: "还款时间",
                clearable: true,
                lableWidth: '50',
                placeholder: "请选择时间",
                valueFormat: "timestamp",
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                    if (minDate && this.pickerMinDate) {
                        this.pickerMinDate = null;
                    } else if (minDate) {
                        this.pickerMinDate = minDate.getTime();
                    }
                    },
                    disabledDate: (time) => {
                    if (this.pickerMinDate) {
                        return (
                        time.getTime() > this.pickerMinDate + this.day31 ||
                        time.getTime() < this.pickerMinDate - this.day31
                        );
                    }
                    return false;
                    },
                },
            },
        ],
        exportLoading: false,
    };
  },
  created() {
    this.getOrderSatatus()
    this.getAllMachineType()
    this.getStoreList()
    this.handleConfirm(this.initData);
  },
  computed: {
    //下单状态
    financeRepayment() {
      return this.$store.state.tagsView.financeRepayment;
    },
  },
  methods: {
    getOrderSatatus(){
      _api.orderStatusList().then((res) => {
        this.statusList = res.data
      });
    },
     //回收商名称下拉
     getAllMachineType() {
      _api
        .listSimpleMerchantView({
          isFilterMatrix: "01",
          isNice: "",
          pageNum: 1,
          pageSize: 999999,
        })
        .then((res) => {
          this.machineType = res.data;
        });
    },
    // 门店商家下拉
    getStoreList() {
      _api.getSelectList().then((res) => {
        if (res.code === 1) {
          const Index = this.formItemList.findIndex(
            (v) => v.key === "companyId"
          );
          this.formItemList[Index].option = res.data.map((r) => {
            return {
              label: r.name,
              value: r.id,
            };
          });        
        }
      });
    },
    handleConfirm(data,cd) {
      let SeachParams = {
        companyId:data&&data.companyId ? data.companyId : "",
        orderNo:data&&data.orderNo ? data.orderNo : "",
        imei:data&&data.imei ? data.imei : "",
        code:data&&data.code ? data.code : "",
        merchantId:this.merchantId,
        state:this.state,
        financeRepaymentType:data&&data.financeRepaymentType ? data.financeRepaymentType : "",
        borrowStartTime:data&&data.entryTime ? moment(data.entryTime[0]).format("x") : "",
        borrowEndTime: data&&data.entryTime ? moment(data.entryTime[1]).format("x") : "",
        repayStartTime:data&&data.refundTime ? moment(data.refundTime[0]).format("x") : "",
        repayEndTime: data&&data.refundTime ? moment(data.refundTime[1]).format("x") : "",
        pageNum: 1,
        pageSize: 10,
        disuseLoding: () => {
          if(typeof cd ==='function'){
             cd();
          }
        },
      };
      this.SeachParams = SeachParams;
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
        //导出Excel
      exportExcel(e) {
      console.log(this.SeachParams);
      this.exportLoading = true;
      _api
        .reportBorrowExcel({ financeRepayment: this.financeRepayment, ...this.SeachParams })
        .then((res) => {
          console.log(res);
          const content = res;
          const blob = new Blob([content]);
          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            //
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                MessageBox.alert(data.msg, "错误提示", {
                  type: "error",
                });
              }
            } catch (err) {
              const fileName = "借款订单.xlsx";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          });
          reader.readAsText(blob, "utf-8");
        }).finally(() => {
          this.exportLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.el_from {
  margin-left: 30px;
  .form_flex_cont {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
  }
  .drawerWith{
    width: 500px;
  }
  .timeStyle{
    margin-top: 15px;
  }
}
</style>
